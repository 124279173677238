<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/preach"
            >云课头条 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            operation == 2 ? "编辑" : "新建"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="all_left_name all_required">标题</div>
        <div>
          <a-input
            v-model="data.title"
            class="all_input all_margin-r"
            placeholder="请输入标题名称"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <div class="all_left_name all_required">主讲老师</div>
        <div>
          <a-input
            v-model="data.lecturer"
            class="all_input all_margin-r"
            placeholder="请输入主讲老师名称"
          />
        </div>
      </div>
      <a-row>
        <a-col :span="8">
          <div class="Up_Down_inner">
            <span class="all_left_name">主图 </span>
            <span class="all_left_name redtext">建议尺寸：374*374px</span>
            <div>
              <a-upload
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="data.mobileImg"
                  :src="data.mobileImg"
                  alt="avatar"
                  class="photo"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
        <!-- <a-col :span="8">
          <div class="Up_Down_inner">
            <span class="all_left_name">pc封面</span>
            <span class="all_left_name redtext">建议尺寸：374*374px</span>
            <div>
              <a-upload
                list-type="picture-card"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest2"
              >
                <img
                  v-if="data.manageImg"
                  :src="data.manageImg"
                  alt="avatar"
                  class="photo"
                />
                <div v-else>
                  <a-icon :type="loading2 ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col> -->
      </a-row>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name">文稿：</span>
          <UE
            editorId="introduceEditor1"
            :initContent="ueInitText"
            @input="getContent"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">上传音频</span>
        <div class="right_Div">
          <a-upload
            :showUploadList="false"
            accept=".mp3,.mp4,.mid,.wma,.m4a,.ogg,.mpc,.ncm,.mflac,.kgm,.xm.MP3,.MP4,MIDI,APE" 
            :customRequest="customRequest3"
          >
            <a-button> <a-icon type="upload" />上传</a-button>
          </a-upload>
        </div>
      </div>
       <div class="audioname">
          <a class='videoName' :href="data.audioUrl" target="_blank">{{data.audioUrl}}</a>
        </div>
    </div>
    <div class="button">
      <a-button type="primary" class="btn" :loading="PreventLoad" @click="onSave()">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: {
    UE,
  }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      file: {},
      loading: false,
      loading2: false,
      loading3: false,
      ueInitText: "",
      ueGetText: "",
      duration: 0,
      data: {
        audioTime: "",
        audioUrl: "",
        content: "",
        // manageImg: "",
        mobileImg: "",
        // sequence: '',
        // seriesId: '',
        title: "",
        lecturer: "",
        // xjType: '',
        xuanId: "",
      },
      PreventLoad: false
    };
  }, // 事件处理器
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.mobileImg = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    customRequest2(fileData) {
      this.loading2 = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.manageImg = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading2 = false;
      });
    },
    customRequest3(e) {
      let url = URL.createObjectURL(e.file);
      let audioElement = new Audio(url);
      let duration = parseInt(audioElement.duration);
        audioElement.addEventListener("loadedmetadata",
          () => {
          this.duration = parseInt(audioElement.duration); //时长为秒，取整          
          // console.log(this.duration);
      });

      this.loading3 = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(e.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.audioUrl = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading3 = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 富文本内容有变化时触发
    getContent(e) {
      this.$set(this, "ueGetText", e);
    },
    getDetail() {
      this.$ajax({
        url: "/hxclass-management/speak/details",
        params: {
          xuanId: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.ueInitText = this.data.content;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onSave() {
      
      if(this.duration){
        let h = parseInt(this.duration / 3600)
        if (h < 10) {
          h = '0' + h
        }
        let m = parseInt(this.duration % 3600 / 60)
        if (m < 10) {
          m = '0' + m
        }
        let s = this.duration % 60
        if (s < 10) {
          s = '0' + s
        }
        this.data.audioTime = h +':' + m + ':' + s;
      }

      this.data.content = this.ueGetText;
      if (this.operation == 3) {
        this.data.parentId = this.id;
      }
      if (!this.data.title) {
        this.$message.warning("请填写标题");
        return;
      }
      if (!this.data.lecturer) {
        this.$message.warning("请填写主讲老师名称");
        return;
      }
      var url = "";
      var method = "";
      if (this.data.xuanId) {
        // 编辑
        url = "/hxclass-management/speak/update";
        method = "put";
      } else {
        // 新增
        url = "/hxclass-management/speak/add";
        method = "post";
      }
      this.PreventLoad = true;
      this.$ajax({
        url: url,
        method: method,
        params: this.data,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.$router.push("/admin/ContentConfig/preach");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.operation = this.$route.query.operation;
    if (this.operation == 2) {
      this.getDetail();
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }
  .photo {
    width: 200px;
  }
  .redtext {
    margin-left: 14px;
    font-size: 12px;
    color: #FF4444;
  }
  .all_content_box {
    .vue-ueditor-wrap[data-v-1e1388a0] {
      width: 800px;
    }
    .L_R_inner {
      margin-top: 24px;
    }
    .Up_Down_inner {
      margin-top: 24px;
      display: block;
      textarea.ant-input,
      .all_input {
        width: 525px;
      }
      textarea.ant-input {
        height: 192px;
      }
      .ant-calendar-picker,
      .input {
        width: 397px;
      }
      .short {
        width: 197px;
      }
    }
  }

  .button {
    .btn {
      margin: 36px 14px;
    }
  }
    .audioname {
    margin-top: 20px;

    .videoName {
      font-style: normal;
      color: @theme;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}
</style>
